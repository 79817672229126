// Using @font-face
// Just only three file formats required
// no need to include files with svg or eot extensions
// With these three formats we have pretty good browsers support e.g.
// Chrome 3.5+, Safari 3+, Firefox 3.5+, Opera 10.1+, IE9+, Android 2.2+, iOS 4.3+

@import './base/variables-paths';

@font-face {
  font-family: 'iconfont';
  src: url('#{$path-fonts}/iconfont.woff') format('woff'),
    url('#{$path-fonts}/iconfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto-Bold'), local('Roboto Bold'), local('sans-serif'),
      url('#{$path-fonts}/Roboto-Bold.woff2') format('woff2'),
      url('#{$path-fonts}/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
font-family: 'Roboto';
src:
    local('Roboto-Light'), local('Roboto Light'), local('sans-serif'),
    url('#{$path-fonts}/Roboto-Light.woff2') format('woff2'),
    url('#{$path-fonts}/Roboto-Light.woff') format('woff');
font-weight: 300;
font-style: normal;
font-display: swap;
}

@font-face {
font-family: 'Roboto';
src:
    local('Roboto'), local('sans-serif'),
    url('#{$path-fonts}/Roboto-Regular.woff2') format('woff2'),
    url('#{$path-fonts}/Roboto-Regular.woff') format('woff');
font-weight: normal;
font-style: normal;
font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src:
        local('Roboto-Medium'), local('Roboto Medium'), local('sans-serif'),
        url('#{$path-fonts}/Roboto-Medium.woff2') format('woff2'),
        url('#{$path-fonts}/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-Italic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-Italic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-Italic.ttf') format('truetype');
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-Thin.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-Thin.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-Thin.ttf') format('truetype');
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-MediumItalic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-MediumItalic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-MediumItalic.ttf') format('truetype');
//     font-weight: 500;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-ThinItalic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-ThinItalic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-ThinItalic.ttf') format('truetype');
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-Black.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-Black.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-Black.ttf') format('truetype');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-BoldItalic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-BoldItalic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-BoldItalic.ttf') format('truetype');
//     font-weight: bold;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-LightItalic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-LightItalic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-LightItalic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'Roboto';
//     src: url('#{$path-fonts}/Roboto-BlackItalic.woff2') format('woff2'),
//         url('#{$path-fonts}/Roboto-BlackItalic.woff') format('woff'),
//         url('#{$path-fonts}/Roboto-BlackItalic.ttf') format('truetype');
//     font-weight: 900;
//     font-style: italic;
// }

